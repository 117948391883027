import {
  cloneElement,
  type ComponentProps,
  forwardRef,
  type ReactElement,
} from "react";

import { Spinner } from "@/components/Spinner";

import { cn } from "@/styles/lib";

export type ButtonProps = ComponentProps<"button"> & {
  color?: "default" | "gold" | "white";
  endIcon?: ReactElement;
  isFullWidth?: boolean;
  isProcessing?: boolean;
  size?: "default" | "sm" | "lg" | "xs";
  startIcon?: ReactElement;
  variant?: "default" | "outlined" | "text";
};

// "border enabled:border-gray-700 enabled:bg-white enabled:hover:border-gray-900 enabled:hover:bg-gray-100  disabled:border disabled:text-gray-300":
//       variant === "outlined",
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      isFullWidth,
      isProcessing,
      children,
      startIcon,
      endIcon,
      variant = "default",
      size = "default",
      type = "button",
      className,
      color = "default",
      ...props
    },
    ref
  ) => (
    <button
      aria-busy={isProcessing}
      aria-disabled={props.disabled}
      className={cn(
        "relative inline-flex select-none items-center justify-center rounded-md px-8 py-2.5 font-semibold tracking-wider transition !duration-[400ms] focus:outline-none",
        {
          "w-full": isFullWidth,
          "enabled:bg-black enabled:text-white enabled:hover:bg-gold enabled:hover:transition-background disabled:bg-gray-300 disabled:text-gray-400":
            variant === "default",
          "border enabled:border-gray-700 enabled:hover:border-gray-900 enabled:hover:bg-black/[.05] disabled:border disabled:text-gray-300":
            variant === "outlined",
          "pointer-events-none": props.disabled || isProcessing,
          "font-normal disabled:text-gray-300": variant === "text",
          "px-6 py-1.5": size === "sm",
          "px-4 py-1.5 text-sm": size === "xs",
          "px-10 text-lg": size === "lg",
          "enabled:bg-gold enabled:hover:bg-black": color === "gold",
          "enabled:border-grey-700 enabled:border enabled:border-gray-700 enabled:bg-white enabled:text-gray-700 enabled:hover:bg-gray-100":
            color === "white",
        },
        className
      )}
      ref={ref}
      type={type}
      {...props}
    >
      {isProcessing && (
        <span className="absolute-center">
          <Spinner
            className={cn({
              "border-white": variant === "default",
              "border-gray-700": variant === "outlined",
              "border-gray-400": props.disabled,
            })}
          />
        </span>
      )}
      <span
        className={cn("inline-flex items-center", {
          "opacity-0": isProcessing,
        })}
      >
        {startIcon &&
          cloneElement(startIcon, {
            ...startIcon?.props,
            className: cn("mr-2 h-6", startIcon.props?.className),
          })}
        {children}
        {endIcon &&
          cloneElement(endIcon, {
            ...endIcon?.props,
            className: cn("ml-2 h-6", endIcon.props?.className),
          })}
      </span>
    </button>
  )
);

Button.displayName = "Button";
