"use client";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";

import { captureError } from "@projectluna/lib/errors";
import { useLocalizedPaths } from "@projectluna/lib/paths/client";

import { Button } from "@/components/Button";
import { Link } from "@/components/Link";

import { ReactComponent as Logo } from "@/assets/svg/logo.svg";

export const ErrorView = ({
  error,
  errorId,
}: {
  error: Error & { digest?: string };
  errorId?: string;
  reset?: () => void;
}) => {
  const [caughtErrorId, setCaughtErrorId] = useState<string | undefined>(
    errorId
  );
  const t = useTranslations();
  const paths = useLocalizedPaths();

  useEffect(() => {
    if (!errorId) {
      setCaughtErrorId(
        captureError(error, {
          extra: {
            digest: error.digest,
          },
        })
      );
    }
  }, [error]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-4">
      <div className="flex w-full flex-col items-center gap-6 text-center md:w-2/3">
        <Link className="flex" href={paths.home.asPath()}>
          <Logo height={350} />
        </Link>

        <p className="text-2xl uppercase">
          {t("error.sorryForInconvenience")}
        </p>

        <hr className="w-full" />

        <p className="text-lg font-light uppercase">
          {t("error.unexpectedErrorOccurred")}
        </p>

        <div>
          <p>{error.message}</p>

          {error?.digest && (
            <p>
              <strong>{t("error.digest")}</strong>: {error.digest}
            </p>
          )}

          {caughtErrorId && (
            <p>
              <strong>{t("error.errorId")}</strong>: {caughtErrorId}
            </p>
          )}
        </div>

        <p>{t("error.passErrorInfo")}</p>

        <div>
          <Button
            endIcon={<ArrowPathIcon />}
            variant="outlined"
            onClick={() => window.location.reload()}
          >
            {t("common.refresh")}
          </Button>
        </div>
      </div>
    </div>
  );
};
